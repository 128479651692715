/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { CSSTransition } from 'react-transition-group'

const style = css`
    display: block;
    height: 55px;
    width: 250px;
    background-color: black;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    transition: clip-path 1s 0ms cubic-bezier(0.83, 0, 0.17, 1),
        color 600ms 200ms cubic-bezier(0.65, 0, 0.35, 1),
        transform 400ms 0ms cubic-bezier(0.33, 1, 0.68, 1);
    color: rgba(255, 255, 255, 1);
    transform: scale(1);

    position: fixed;
    bottom: 50px;
    left: auto;
    right: auto;
    z-index: 20;
    clip-path: inset(100% round 40px);

    & > div {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(0%);
        transition-property: transform;
        transition-delay: 0ms;
        transition-duration: 400ms;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        user-select: none;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        padding-bottom: 5px;
    }

    &:hover {
        & > div {
            transform: translateY(-100%);
        }

        transform: scale(1.05);
    }

    &.scale-appear {
        &-enter {
            clip-path: inset(100% round 40px);
            color: rgba(255, 255, 255, 0);

            &-active,
            &-done {
                color: rgba(255, 255, 255, 1);
                clip-path: inset(0% round 40px);
            }
        }

        &-exit {
            clip-path: inset(0% round 40px);
            color: rgba(255, 255, 255, 1);

            &-active,
            &-done {
                color: rgba(255, 255, 255, 0);
                clip-path: inset(100% round 40px);
            }
        }
    }

    @media all and (orientation: landscape) {
        transform: scale(0.8);
    }
`

export const AnimatedButton = ({
    onClick,
    enter,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <CSSTransition
            in={enter}
            classNames="scale-appear"
            // unmountOnExit
            // mountOnEnter
            timeout={1000}
        >
            <div
                css={style}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div>Unwrap your gift</div>
                <div>Unwrap your gift</div>
            </div>
        </CSSTransition>
    )
}
