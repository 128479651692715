/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { starVersions, Star } from './Star'

const style = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;

    .star {
        position: absolute;
        transform: translate(-50%, -50%);
    }
`

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

export const StrarManager = ({ start = false }) => {
    const stars = useRef([])
    const idCount = useRef(0)
    const intervalRef = useRef(null)
    const forceUpdate = useForceUpdate()

    const killStar = useCallback((id) => {
        const index = stars.current.findIndex((el) => el.id === id)

        if (index >= 0) {
            stars.current.splice(index, 1)
        } else {
            return
        }
    }, [])

    const spawnStar = useCallback(() => {
        if (stars.current.length >= 3) {
            // max of 3 stars
            return
        }

        const starData = {
            version: Math.ceil(Math.random() * (starVersions.length - 1)),
            id: idCount.current,
            style: {
                top: `${Math.random() * window.innerHeight}px`,
                left: `${Math.random() * window.innerWidth}px`,
            },
        }

        idCount.current++

        stars.current.push(starData)
    }, [])

    useEffect(() => {
        if (start) {
            clearInterval(intervalRef.current)
            intervalRef.current = setInterval(() => {
                spawnStar()
                forceUpdate()
            }, 1000)
        } else {
            clearInterval(intervalRef.current)
        }

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [forceUpdate, spawnStar, start])

    return (
        <div css={style} className="star-manager">
            {stars.current.map((el) => (
                <Star
                    version={el.version}
                    key={el.id}
                    id={el.id}
                    style={el.style}
                    className="star"
                    onComplete={killStar}
                />
            ))}
        </div>
    )
}
