import React, { useEffect, useMemo, useRef } from 'react'
import Lottie from 'lottie-web'

import star1 from '../lottie-files/Stars/Star-50px.json'
import star1r from '../lottie-files/Stars/Star-50pxR.json'
import star2 from '../lottie-files/Stars/Star-100px.json'
import star2r from '../lottie-files/Stars/Star-100pxR.json'
import star3 from '../lottie-files/Stars/Star-200px.json'
import star3r from '../lottie-files/Stars/Star-200pxR.json'

export const starVersions = [star1, star1r, star2, star2r, star3, star3r]

export const Star = React.memo(
    ({ version = 0, className, style = {}, id, onComplete = () => {} }) => {
        const container = useRef(null)
        const lottieAnimation = useRef(null)

        useEffect(() => {
            if (version === null) {
                if (lottieAnimation.current) {
                    lottieAnimation.current.destroy()
                }
                return
            }

            if (lottieAnimation.current) {
                lottieAnimation.current.destroy()
            }

            let animation

            switch (version) {
                case 1:
                    animation = star1
                    break
                case 2:
                    animation = star1r
                    break
                case 3:
                    animation = star2
                    break
                case 4:
                    animation = star2r
                    break
                case 5:
                    animation = star3
                    break
                case 6:
                    animation = star3r
                    break
                default:
                    throw new Error('UNKNOWN STAR VERSION')
            }

            lottieAnimation.current = Lottie.loadAnimation({
                animationData: animation,
                loop: false,
                autoplay: true,
                container: container.current,
                renderer: 'svg',
            })

            const completeHandler = onComplete.bind(this, id)
            lottieAnimation.current.addEventListener(
                'complete',
                completeHandler
            )

            return () => {
                lottieAnimation.current.removeEventListener(
                    'complete',
                    completeHandler
                )
            }
        }, [id, onComplete, version])

        return (
            <div
                key={id}
                id={id}
                ref={container}
                className={className}
                style={style}
            ></div>
        )
    },
    (prevProps, nextProps) =>
        prevProps.version === nextProps.version &&
        prevProps.className === nextProps.className &&
        prevProps.style === nextProps.style &&
        prevProps.id === nextProps.id &&
        prevProps.onComplete === nextProps.onComplete
)
