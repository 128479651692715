/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { TreeCircle } from './Components/TreeCircle'

import './App.css'
import { Logo } from './Components/Logo'
import { LetItSnowGrow } from './Components/LetItSnowGrow'
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import { LottieContainer } from './Components/LottieContainer'
import { AnimatedButton } from './Components/AnimatedButton'
import gsap, { Cubic, Linear, Expo, Bounce, Back, Circ } from 'gsap'
import classSet from 'classset'
import { debounce } from 'debounce'
import { ResizeMask } from './Components/ResizeMask'
import { Star } from './Components/Star'
import { StrarManager } from './Components/StarManager'

const styles = css`
    width: 100vh;
    height: 100vh;
    margin-top: 0;
    margin-left: calc((100vh - 100vw) / 2 * -1);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .reveal-copy {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        position: fixed;
        bottom: 50px;
        opacity: 0;
        user-select: none;
    }

    .star-wrapper {
        .star {
            position: fixed;
            &-1 {
                top: 30px;
                right: 50px;
                width: 16.875vw;
            }

            &-2 {
                bottom: 100px;
                right: -7vw;
                width: 20vw;
            }

            &-3 {
                left: -9vw;
                top: 40%;
                width: 24.375vw;
            }
        }
    }

    @media screen and (orientation: landscape) {
        width: 100vw;
        height: 100vw;
        margin-top: calc((100vw - 100vh) / 2 * -1);
        margin-left: 0;

        .star-wrapper {
            .star {
                &-1 {
                    top: 30px;
                    right: 50px;
                    width: 16.875vw;
                    max-width: 100px;
                }

                &-2 {
                    bottom: 20px;
                    right: -7vw;
                    width: 20vw;
                    max-width: 200px;
                }

                &-3 {
                    left: -9vw;
                    top: 40%;
                    width: 24.375vw;
                    max-width: 300px;
                }
            }
        }
    }

    @media screen and (orientation: landscape) and (min-width: 1024px) {
        .star-wrapper {
            .star {
                &-1 {
                    top: 30px;
                    right: 50px;
                    width: 100px;
                }

                &-2 {
                    bottom: 100px;
                    right: 30px;
                    width: 200px;
                }

                &-3 {
                    left: -100px;
                    top: 40%;
                    width: 300px;
                }
            }
        }
    }
`

const copyStyle = css`
    font-family: 'Graphik', sans-serif;
    width: 300px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    user-select: none;
    hyphens: auto;
    /* mask-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 0%
    );

    transition: 400ms;

    &.show {
        mask-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1) 100%,
            rgba(255, 255, 255, 0) 100%
        );
    } */
`

function App() {
    const baseCircleSize = 300
    const circleSpacing = 150
    const [rotationActive, setRotationActive] = useState(false)
    const [currentLottieAnim, setCurrentLottieAnim] = useState(null)
    const [showButton, setShowButton] = useState(false)
    const logoRef = useRef(null)
    const letItSnowRef = useRef(null)
    const circleRef = useRef(null)
    const lottieContainerRef = useRef(null)
    const mainTl = useRef(null)
    const [hovering, setHovering] = useState(null)
    const [portrait, setPortrait] = useState(
        window.matchMedia('(orientation: portrait)').matches
    )

    const [circleSizeFactor, setCircleSizeFactor] = useState(1)
    const [starsEnabled, setStarsEnabled] = useState(false)

    const circle2Ref = useRef(null)
    const circle3Ref = useRef(null)
    const circle4Ref = useRef(null)

    const starContainer = useRef(null)

    const copyRef = useRef(null)
    const treeCopy = useRef(null)
    const endCopy = useRef(null)

    const matchScaling = useCallback(() => {
        // tries to match the scaling of the circle tree to the littie tree
        const lottieWidth =
            lottieContainerRef.current.getBoundingClientRect().width
        const treeWidth = document.querySelector('.tree').offsetWidth
        console.log('CALCULATION!')
        return lottieWidth / treeWidth
    }, [])

    const setUpLogoTimeLine = useCallback((tl, startTime) => {
        let q = gsap.utils.selector(logoRef.current)

        tl.add('logo_start', startTime)

        tl.fromTo(
            logoRef.current,
            { y: window.innerHeight / 2 - 50, x: window.innerWidth },
            {
                y: window.innerHeight / 2 - 50,
                x: 0,
                duration: 1,
                ease: Expo.easeOut,
            },
            'logo_start'
        )

        tl.add('TextFadeIn', 'logo_start+=1')

        tl.fromTo(
            q('#ERREN'),
            { opacity: 0 },
            { opacity: 1, duration: 0.2, ease: Cubic.easeInOut },
            'TextFadeIn'
        )
        tl.fromTo(
            q('#ER'),
            { opacity: 0 },
            { opacity: 1, duration: 0.6, ease: Cubic.easeInOut },
            'TextFadeIn'
        )
        tl.fromTo(
            q('#CHÖPFUNG'),
            { opacity: 0 },
            { opacity: 1, duration: 1.2, ease: Cubic.easeInOut },
            'TextFadeIn'
        )

        tl.fromTo(
            logoRef.current,
            { y: window.innerHeight / 2 - 50, x: 0 },
            {
                y: 0,
                x: 0,
                duration: 1,
                ease: Expo.easeOut,
            },
            'logo_start+=2'
        )
    }, [])

    const setUpLetItSnowTimeLine = useCallback((tl, startTime) => {
        const q = gsap.utils.selector(letItSnowRef.current)

        tl.add('letItSnow_start', startTime)
        tl.fromTo(
            q('div:nth-child(3)'),
            { opacity: 0 },
            { opacity: 1, duration: 0.6, ease: Cubic.easeInOut },
            'letItSnow_start'
        )
        tl.fromTo(
            q('div:nth-child(2)'),
            { opacity: 0 },
            { opacity: 1, duration: 0.6, ease: Cubic.easeInOut },
            'letItSnow_start+=0.6'
        )
        tl.fromTo(
            q('div:nth-child(1)'),
            { opacity: 0 },
            { opacity: 1, duration: 0.6, ease: Cubic.easeInOut },
            'letItSnow_start+=1.2'
        )

        tl.add('letItSnow_phase-2')

        tl.to(
            q('div:nth-child(3)'),
            { y: '100%', opacity: 0, duration: 1, ease: Cubic.easeInOut },
            'letItSnow_phase-2'
        )
        tl.to(
            q('div:nth-child(2)'),
            { y: '100%', opacity: 0, duration: 1, ease: Cubic.easeInOut },
            'letItSnow_phase-2'
        )
        tl.to(
            q('div:nth-child(1)'),
            { y: '100%', opacity: 1, duration: 1, ease: Cubic.easeInOut },
            'letItSnow_phase-2'
        )

        tl.to(
            q('div:nth-child(1)'),
            { opacity: 0, duration: 0.5, ease: Linear },
            'letItSnow_start+=3.1'
        )
    }, [])

    const setUpCircleScaleDownTimeLine = useCallback((tl, startTime) => {
        const screenDiagonal = Math.sqrt(
            Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
        )
        tl.add('circle_scale_start', startTime)
        tl.fromTo(
            circleRef.current,
            {
                scale: screenDiagonal / 300,
            },
            { scale: 1, duration: 2 },
            'circle_scale_start'
        )
    }, [])

    const setUpLottieAppearTimeline = useCallback((tl, startTime) => {
        tl.add('lottie_appear_start', startTime)
        tl.set(lottieContainerRef.current, { y: '-37.2670807453%' })
        tl.fromTo(
            lottieContainerRef.current,
            {
                scale: 1.35,
            },
            {
                scale: 1,
                duration: 0.3,
                ease: Cubic.easeOut,
            },
            'lottie_appear_start'
        )
    }, [])

    const setUpLottieScene3Transition = useCallback((tl, startTime) => {
        // remove what we moved up before
        tl.add('scene_3_transition_start', startTime)

        tl.to(
            lottieContainerRef.current,
            { scale: 4, y: '-200%', duration: 0.1, ease: Circ.easeOut },
            'scene_3_transition_start+=1.2'
        )
        tl.to(lottieContainerRef.current, { scale: 0.8, y: 0, duration: 0.5 })

        tl.to(
            lottieContainerRef.current,
            { y: 0, duration: 0.5, ease: Bounce.easeOut },
            'scene_3_transition_start+=1.6'
        )
        tl.to(
            lottieContainerRef.current,
            { scale: 0.8, duration: 0.6, ease: Expo.easeOut },
            'scene_3_transition_start+=1.6'
        )

        tl.fromTo(
            treeCopy.current,
            { y: 200, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, ease: Cubic.easeOut },
            'scene_3_transition_start+=1.2'
        )
    }, [])

    const setUpCircleScaleOutTimeLine = useCallback(
        (tl, startTime, onComplete) => {
            const screenDiagonal = Math.sqrt(
                Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
            )
            const newScale = screenDiagonal / 300

            tl.add('scaleOut_start', startTime)

            tl.to(
                circleRef.current,
                {
                    scale: newScale,
                    duration: 1.4 * (newScale / 5),
                    ease: Cubic.easeOut,
                    onComplete: onComplete,
                },
                'scaleOut_start+=1.4'
            )
        },
        []
    )

    const setUpOutroTransitionTimeLine = useCallback(
        (tl, startTime) => {
            // let q = gsap.utils.selector(starContainer.current)
            const tree = document.querySelector('.tree')
            const firstTree = tree.querySelector('.first-tree')
            const redTree = tree.querySelector('.red-tree')
            const secondTree = document.querySelector('.tree:nth-child(2) img')
            const lastTree = document.querySelector('.tree:last-child img')

            tl.call(setStarsEnabled, [true])

            tl.set(
                circleRef.current,
                {
                    scale: matchScaling,
                },
                '+=0.1'
            )

            tl.set(
                circleRef.current,
                {
                    y: () =>
                        lottieContainerRef.current.getBoundingClientRect().y -
                        tree.getBoundingClientRect().y,
                },
                '+=0.1'
            )

            tl.set(tree, { opacity: 0 })
            tl.set(firstTree, { display: 'none' })
            tl.set(redTree, { display: 'block' })

            // tl.addPause()

            tl.fromTo(
                secondTree,
                { x: (window.innerWidth / 2) * -1 },
                { x: 0, duration: 1, ease: Cubic.easeOut }
            )

            tl.fromTo(
                lastTree,
                { x: window.innerWidth / 2 },
                { x: 0, duration: 1, ease: Cubic.easeOut },
                '-=1'
            )

            // tl.addPause()

            tl.set(tree, { opacity: 1 })
            tl.set(lottieContainerRef.current, { opacity: 0 })

            tl.to(circleRef.current, {
                scale: 1,
                y: 0,
                duration: 2,
                ease: Cubic.easeOut,
            })

            tl.to(
                treeCopy.current,
                {
                    opacity: 0,
                    duration: 1,
                    ease: Cubic.easeInOut,
                },
                '-=2'
            )

            tl.call(setRotationActive, [true], '-=1')

            const screenDiagonal = Math.sqrt(
                Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
            )

            tl.add('circle_end')

            tl.fromTo(
                endCopy.current,
                { opacity: 0 },
                { opacity: 1, duration: 1, ease: Cubic.easeInOut }
            )

            tl.fromTo(
                circle2Ref.current,
                { scale: screenDiagonal / 450 },
                { scale: 1, display: 'block', duration: 1, ease: Expo.easeOut },
                'circle_end'
            )
            tl.fromTo(
                circle3Ref.current,
                { scale: screenDiagonal / 550 },
                { scale: 1, display: 'block', duration: 1, ease: Expo.easeOut },
                'circle_end+=0.3'
            )
            tl.fromTo(
                circle4Ref.current,
                { scale: screenDiagonal / 650 },
                { scale: 1, display: 'block', duration: 1, ease: Expo.easeOut },
                'circle_end+=0.6'
            )

            tl.fromTo(
                copyRef.current,
                { y: -50, opacity: 0 },
                { y: 0, opacity: 1, duration: 1, ease: Cubic.easeOut },
                'circle_end'
            )

            // tl.fromTo(
            //     q('.star.star-1'),
            //     { scale: 0, display: 'none' },
            //     {
            //         scale: 1,
            //         display: 'block',
            //         duration: 1,
            //         ease: Back.easeInOut,
            //     },
            //     'circle_end+=0.5'
            // )
            // tl.fromTo(
            //     q('.star.star-2'),
            //     { scale: 0, display: 'none' },
            //     {
            //         scale: 1,
            //         display: 'block',
            //         duration: 1,
            //         ease: Back.easeInOut,
            //     },
            //     'circle_end+=0.6'
            // )
            // tl.fromTo(
            //     q('.star.star-3'),
            //     { scale: 0, display: 'none' },
            //     {
            //         scale: 1,
            //         display: 'block',
            //         duration: 1,
            //         ease: Back.easeInOut,
            //     },
            //     'circle_end+=0.8'
            // )

            // tl.to(circleRef.current, { opacity: 1, duration: 1, Linear })
        },
        [matchScaling]
    )

    const resetStyles = useCallback((tl, startTime) => {
        const tree = document.querySelector('.tree')
        const firstTree = tree.querySelector('.first-tree')
        const redTree = tree.querySelector('.red-tree')

        tl.set(tree, { opacity: 1 })
        tl.set(firstTree, { display: 'block' })
        tl.set(redTree, { display: 'none' })

        tl.set(lottieContainerRef.current, { opacity: 1 })
    }, [])

    const buildTimeLine = useCallback(
        (restoreTime = 0, paused = false) => {
            if (mainTl.current) {
                mainTl.current.kill()
            }

            mainTl.current = new gsap.timeline({ paused: true })

            // mainTl.current.set(
            //     [circle2Ref.current, circle3Ref.current, circle4Ref.current],
            //     { display: 'none' }
            // )

            resetStyles(mainTl.current)

            setUpLogoTimeLine(mainTl.current, 0)
            setUpLetItSnowTimeLine(mainTl.current, 3)

            mainTl.current.call(setRotationActive, [true], 6.6)
            mainTl.current.call(setShowButton, [true], 6.6)

            setUpLottieAppearTimeline(mainTl.current, 6.6)

            mainTl.current.call(setCurrentLottieAnim, [0], 6.6) // landing anim

            setUpCircleScaleDownTimeLine(mainTl.current, 6.5)

            mainTl.current.call(setCurrentLottieAnim, [1], 7.6) // idle anim
            mainTl.current.addPause(8, () => {
                console.log('PAUSE')
            })

            setUpLottieScene3Transition(mainTl.current, 8.01)
            setUpCircleScaleOutTimeLine(mainTl.current, 8.01, () => {
                setRotationActive(false)
            })
            mainTl.current.call(setCurrentLottieAnim, [2], 8.01) // open anim
            mainTl.current.call(setShowButton, [false], 8.01)

            mainTl.current.add('outro_start', 11.3054734)

            setUpOutroTransitionTimeLine(mainTl.current, 11.3054734)

            console.log(restoreTime)
            mainTl.current.seek(restoreTime)

            if (!paused) {
                mainTl.current.play()
            }

            global.tl = mainTl.current
        },
        [
            resetStyles,
            setUpCircleScaleDownTimeLine,
            setUpCircleScaleOutTimeLine,
            setUpLetItSnowTimeLine,
            setUpLogoTimeLine,
            setUpLottieAppearTimeline,
            setUpLottieScene3Transition,
            setUpOutroTransitionTimeLine,
        ]
    )

    const handleClick = useCallback(() => {
        if (mainTl.current) {
            mainTl.current.play()
        }
    }, [])

    useEffect(() => {
        console.log('anim: ', currentLottieAnim)
    }, [currentLottieAnim])

    useEffect(() => {
        if (showButton && hovering !== null) {
            if (hovering) {
                gsap.to(circleRef.current, {
                    scale: 0.85,
                    duration: 0.6,
                    ease: Back.easeOut,
                })

                gsap.to(lottieContainerRef.current, {
                    scale: 1.15,
                    y: '-=7%',
                    duration: 0.4,
                    ease: Expo.easeOut,
                })
            } else {
                gsap.to(circleRef.current, {
                    scale: 1,
                    duration: 0.6,
                    ease: Back.easeOut,
                })
                gsap.to(lottieContainerRef.current, {
                    scale: 1,
                    y: '+=7%',
                    duration: 0.4,
                    ease: Expo.easeOut,
                })
            }
        }
    }, [hovering, showButton])

    const determineScaleFactor = useCallback(() => {
        if (portrait) {
            // in portrait mode screen width sets
            setCircleSizeFactor(window.innerWidth / (baseCircleSize + 200))
        } else {
            setCircleSizeFactor(
                window.innerHeight / ((baseCircleSize + 100) * 1.4)
            )
        }
    }, [portrait])

    useLayoutEffect(() => {
        setTimeout(buildTimeLine, 100)
    }, [buildTimeLine, circleSizeFactor])

    const handleChanges = useCallback(() => {
        let restore = mainTl.current.time()
        let paused = mainTl.current.paused()

        // mainTl.current
        //     .invalidate() // clear timeline values
        //     .restart() // set time to start so that initial values can be recorded
        //     .time(restore) // set time to same position as before

        setRotationActive(false)
        setHovering(null)
        setCurrentLottieAnim(null)
        setStarsEnabled(false)

        determineScaleFactor()
        buildTimeLine(restore, paused)
    }, [buildTimeLine, determineScaleFactor])

    useEffect(() => {
        const debounceHandler = debounce(handleChanges, 200)
        window.addEventListener('resize', debounceHandler)

        determineScaleFactor()

        return () => {
            window.removeEventListener('resize', debounceHandler)
        }
    }, [determineScaleFactor, handleChanges])

    return (
        <div css={styles}>
            <ResizeMask />
            <Logo key="logo" startTime={0} ref={logoRef} />
            <LetItSnowGrow key="lis#1" startTime={0} ref={letItSnowRef} />
            <TreeCircle
                key={`trees`}
                numberOfTrees={Math.round(14 * circleSizeFactor)}
                baseRadius={baseCircleSize * circleSizeFactor}
                rotation={rotationActive}
                ref={circleRef}
                hovering={hovering}
            />
            <TreeCircle
                key="trees_row_2"
                numberOfTrees={Math.round(20 * circleSizeFactor)}
                baseRadius={
                    (baseCircleSize + circleSpacing * 1) * circleSizeFactor
                }
                rotation={rotationActive}
                ref={circle2Ref}
            />
            <TreeCircle
                key="trees_row_3"
                numberOfTrees={Math.round(22 * circleSizeFactor)}
                baseRadius={
                    (baseCircleSize + circleSpacing * 2) * circleSizeFactor
                }
                rotation={rotationActive}
                ref={circle3Ref}
            />
            <TreeCircle
                key="trees_row_4"
                numberOfTrees={Math.round(24 * circleSizeFactor)}
                baseRadius={
                    (baseCircleSize + circleSpacing * 3) * circleSizeFactor
                }
                rotation={rotationActive}
                ref={circle4Ref}
            />
            <LottieContainer
                animation={currentLottieAnim}
                ref={lottieContainerRef}
            />
            <AnimatedButton
                onClick={handleClick}
                enter={showButton}
                onMouseEnter={setHovering.bind(this, true)}
                onMouseLeave={setHovering.bind(this, false)}
            />
            <p css={copyStyle} className={classSet('copy')} ref={copyRef}>
                We planted your very own fir, right here in Germany. A small
                contribution to saving the climate for future generations. We’ll
                get in touch with you soon with some further details on the
                whereabouts of your very own fir tree.
            </p>
            <p className="reveal-copy" ref={treeCopy}>
                Oh, how beautiful!
                <br />
                It's a tree!
            </p>
            <p className="reveal-copy" ref={endCopy}>
                Happy Holidays
            </p>
            {/* <div className="star-wrapper" ref={starContainer}>
                <Star version={1} className="star star-1" />
                <Star version={3} className="star star-2" />
                <Star version={2} className="star star-3" />
            </div> */}

            <StrarManager start={starsEnabled} />
        </div>
    )
}

export default App
