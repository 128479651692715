/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Lottie from 'lottie-web'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import landing from '../lottie-files/1_Stage_Landing.json'
import idle from '../lottie-files/2_Stage_Shake.json'
import open from '../lottie-files/3_Stage_Opening.json'
import starLoop from '../lottie-files/4_Stage_StarLoop.json'

const style = css`
    position: fixed;

    svg > g > g > g:nth-child(12) path {
        fill: red;
    }

    @media all and (max-height: 700px) {
        height: 60vh;

        .lottie-container {
            height: 100%;
        }
    }
`

export const LottieContainer = React.forwardRef(
    ({ active = false, animation }, ref) => {
        const [currentAnimationIdx, setCurrentAnimationIdx] = useState(null)
        const container = useRef(null)
        const lottieAnimation = useRef(null)
        const sequence = useRef([
            {
                animationData: landing, // duration 1s
                loop: false,
                autoplay: false,
            },
            {
                animationData: idle, // duration 12s
                loop: true,
                autoplay: true,
            },
            {
                animationData: open, // duration 6s
                loop: false,
                autoplay: true,
            },
            {
                animationData: starLoop,
                loop: true,
                autoplay: true,
            },
        ])

        useEffect(() => {
            if (animation < sequence.current.length && sequence)
                setCurrentAnimationIdx(animation)
        }, [animation])

        const handleOnComplete = useCallback(() => {
            setCurrentAnimationIdx(currentAnimationIdx + 1)
        }, [currentAnimationIdx])

        useEffect(() => {
            if (currentAnimationIdx === null) {
                if (lottieAnimation.current) {
                    lottieAnimation.current.destroy()
                }
                return
            }

            if (lottieAnimation.current) {
                lottieAnimation.current.destroy()
            }

            lottieAnimation.current = Lottie.loadAnimation({
                ...sequence.current[currentAnimationIdx],
                container: container.current,
                renderer: 'svg',
                autoplay: true,
            })

            // lottieAnimation.current.addEventListener('complete', handleOnComplete)

            // return () => {
            //     lottieAnimation.current.removeEventListener(
            //         'complete',
            //         handleOnComplete
            //     )
            // }
        }, [currentAnimationIdx, handleOnComplete])

        return (
            <div css={style} ref={ref}>
                <div className="lottie-container" ref={container}></div>
            </div>
        )
    }
)
