/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { debounce } from 'debounce'
import Lottie from 'lottie-web'
import { useEffect, useRef, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import loadingAnimation from '../lottie-files/0_Stage_Resize.json'

const style = css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(235, 234, 229);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ResizeMask = () => {
    const [show, setShow] = useState(false)
    const lottieContainer = useRef(null)
    const lottieAnimation = useRef(null)
    const [exited, setExited] = useState(false)

    useEffect(() => {
        const handler = debounce(
            () => {
                setShow(true)
            },
            500,
            true
        )

        const secondHandler = debounce(
            () => {
                setShow(false)
            },
            500,
            false
        )

        window.addEventListener('resize', handler)
        window.addEventListener('resize', secondHandler)

        return () => {
            window.removeEventListener('resize', handler)
            window.removeEventListener('resize', secondHandler)
        }
    }, [])

    useEffect(() => {
        if (show) {
            setExited(false)
            if (lottieAnimation.current) {
                lottieAnimation.current.destroy()
            }
            lottieAnimation.current = Lottie.loadAnimation({
                animationData: loadingAnimation,
                container: lottieContainer.current,
                renderer: 'svg',
                autoplay: true,
                loop: true,
            })
        } else if (!show && exited) {
            if (lottieAnimation.current) {
                lottieAnimation.current.destroy()
            }
        }
    }, [exited, show])

    return (
        <TransitionGroup component={null} enter exit appear>
            {show && (
                <CSSTransition
                    key="blocker"
                    timeout={500}
                    onExited={() => {
                        setExited(true)
                    }}
                >
                    <div css={style} className="resizeBlocker">
                        <div ref={lottieContainer}></div>
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
