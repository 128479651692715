/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'

const style = css`
    position: absolute;
    /* top: 50%; */
    left: auto;
    right: auto;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;

    & > div {
        opacity: 0;
    }
`

export const LetItSnowGrow = React.forwardRef((props, ref) => {
    return (
        <div css={style} ref={ref}>
            <div>Let it grow!</div>
            <div>Let it snow!</div>
            <div>Let it snow!</div>
        </div>
    )
})
