/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import classSet from 'classset'
import treeSVG from '../'

const rotation = keyframes`
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
`

const counterRotation = keyframes`
    from {
        transform: rotate(0deg) 
    }

    to {
        transform: rotate(-360deg) 
    }
`

const baseStyle = css`
    width: 100vw;
    height: 100vw;
    animation: none;
    position: absolute;

    .helper {
        height: 100%;
    }

    .tree {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        user-select: none;

        img {
            width: 70px;
            height: auto;
        }
    }

    &.active {
        animation-name: ${rotation};
        animation-duration: 20s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        .tree {
            animation-duration: 20s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            transform-origin: center;

            img {
                animation-name: ${counterRotation};
                animation-duration: 20s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                transform-origin: center;
            }
        }
    }
`

const treeVersions = [
    '/Baum_Rauten.svg',
    '/Baum_Kreise.svg',
    '/Baum_Dreiecke.svg',
    '/Baum_Dreiecke_rot.svg',
]

export const TreeCircle = React.forwardRef(
    ({ numberOfTrees = 0, baseRadius = 0, rotation = true }, ref) => {
        const [animationEnabled, setAnimationEnabled] = useState(false)
        const [trees, setTrees] = useState(new Array(numberOfTrees).fill(''))
        const [treeStyles, setTreeStyles] = useState('')
        const containerRef = useRef(null)

        const getCircleCoords = useCallback((angle, radius) => {
            return {
                x: radius * Math.sin((Math.PI * 2 * angle) / 360),
                y: radius * Math.cos((Math.PI * 2 * angle) / 360),
            }
        }, [])

        const resetAnimation = useCallback((stop) => {
            containerRef.current.getAnimations().forEach((anim) => {
                anim.currentTime = 0
            })
            containerRef.current.querySelectorAll('.tree').forEach((el) => {
                el.getAnimations().forEach((anim) => {
                    anim.currentTime = 0
                    if (stop) {
                        anim.cancel()
                    } else {
                        anim.play()
                    }
                })
            })

            containerRef.current.querySelectorAll('.tree img').forEach((el) => {
                el.getAnimations().forEach((anim) => {
                    anim.currentTime = 0
                    if (stop) {
                        anim.cancel()
                    } else {
                        anim.play()
                    }
                })
            })
        }, [])

        useEffect(() => {
            requestAnimationFrame(() => {
                setAnimationEnabled(rotation)
            })
        }, [resetAnimation, rotation])

        useEffect(() => {
            // resetAnimation()
        }, [animationEnabled, resetAnimation])

        useEffect(() => {
            // generate Tree Animations
            let tmp = []
            let anims = []
            let newTrees = []

            for (let i = 0; i < numberOfTrees; i++) {
                const coords = getCircleCoords(
                    (i / numberOfTrees) * 360 + 180, // +180 so that the first tree is on top
                    baseRadius
                )
                const coordsEnd = getCircleCoords(
                    (i / numberOfTrees) * 360 + 180, // +180 so that the first tree is on top
                    baseRadius + 100
                )

                anims[i] = keyframes`
                0% {
                    transform: translate(-50%, -50%) translate3d(${coords.x}px, ${coords.y}px, 0);
                }

                50% {
                    transform: translate(-50%, -50%) translate3d(${coordsEnd.x}px, ${coordsEnd.y}px, 0)
                }

                100% {
                    transform: translate(-50%, -50%) translate3d(${coords.x}px, ${coords.y}px, 0);
                }
            `

                tmp[i] = css`
                    .tree:nth-of-type(${i + 1}) {
                        transform: translate(-50%, -50%)
                            translate3d(${coords.x}px, ${coords.y}px, 0);
                    }

                    &.active .tree:nth-of-type(${i + 1}) {
                        animation-name: ${anims[i]};
                    }
                `

                newTrees[i] =
                    i === 0
                        ? treeVersions[0]
                        : treeVersions[
                              Math.round(
                                  Math.random() * (treeVersions.length - 2)
                              )
                          ]
            }
            setTreeStyles(tmp)
            setTrees(newTrees)
        }, [baseRadius, getCircleCoords, numberOfTrees])

        useEffect(() => {
            console.log('animation helper')
            if (containerRef.current.classList.contains('active')) {
                console.log('animation helper 2')
                containerRef.current.classList.remove('active')

                setTimeout(() => {
                    console.log(
                        'animation helper 3',
                        containerRef.current.classList.contains('active')
                    )
                    containerRef.current.classList.add('active')
                }, 100)
            }
        }, [trees])

        return (
            <div
                css={[baseStyle, ...treeStyles]}
                style={{
                    width: `${(baseRadius + 100) * 2}px`,
                    height: `${(baseRadius + 100) * 2}px`,
                }}
                className={classSet('tree-circle', {
                    active: animationEnabled,
                })}
                ref={containerRef}
            >
                <div className="helper" ref={ref} key="helperEl">
                    {trees.map((el, index) => (
                        <div
                            className="tree"
                            key={`tree_${numberOfTrees}_${index}`}
                        >
                            <img
                                className={index === 0 && 'first-tree'}
                                src={el}
                                alt="tree"
                            />
                            {index === 0 && (
                                <img
                                    className="red-tree"
                                    src={treeVersions[3]}
                                    alt="red-tree"
                                    style={{ display: 'none' }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
)
