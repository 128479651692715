/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import React from 'react'

const style = css`
    position: fixed;
    top: 50px;
    left: auto;
    right: auto;
    white-space: nowrap;
    z-index: 10;

    svg {
        width: 220px;
        height: auto;
    }
`

/**
 * @typedef {Object} TimeLineRef
 * @property {gsap.timeline} current The Timeline
 */

export const Logo = React.forwardRef(
    ({ active = false, startTime = 0 }, ref) => {
        return (
            <div css={style} ref={ref}>
                <svg
                    id="HDS_LOGO_WORDMARK"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 29.27"
                >
                    <g id="CHÖPFUNG">
                        <path
                            fill="black"
                            d="M199.57,23a6.39,6.39,0,0,1-6.56,6.3h-.13a6.52,6.52,0,0,1-6.65-6.34V12.41a6.49,6.49,0,0,1,6.65-6.3H193a6.38,6.38,0,0,1,6.56,6.66h-4A2.67,2.67,0,0,0,193,9.87a2.45,2.45,0,0,0-2.61,2.35V23.13A2.48,2.48,0,0,0,193,25.51a2.45,2.45,0,0,0,2.54-2.35V21.94h4Z"
                        />
                        <polygon
                            fill="black"
                            points="216.77 6.43 216.77 28.95 212.72 28.95 212.72 19.07 207.35 19.07 207.35 28.95 203.33 28.95 203.33 6.43 207.35 6.43 207.35 15.25 212.72 15.25 212.72 6.43 216.77 6.43"
                        />
                        <path d="M234.56,23a6.46,6.46,0,0,1-6.6,6.3h-.51a6.52,6.52,0,0,1-6.63-6.34V12.42a6.49,6.49,0,0,1,6.63-6.31H228a6.47,6.47,0,0,1,6.6,6.31ZM222,0h4.06V4.12H222Zm8.5,12.22a2.49,2.49,0,0,0-2.61-2.35h-.35a2.52,2.52,0,0,0-2.64,2.35V23.13a2.54,2.54,0,0,0,2.64,2.38h.32a2.52,2.52,0,0,0,2.64-2.35ZM229.25,0h4.09V4.12h-4.09Z" />
                        <path d="M238.61,6.43h6.66A5.57,5.57,0,0,1,251,11.87v4.31a5.55,5.55,0,0,1-5.73,5.47h-2.54V29h-4.12Zm8.2,5.28a1.59,1.59,0,0,0-1.67-1.51h-2.41v7.72h2.41a1.59,1.59,0,0,0,1.67-1.48Z" />
                        <polygon points="266.95 6.43 266.95 10.2 258.91 10.2 258.91 15.18 266.18 15.18 266.18 19.07 258.91 19.07 258.91 28.95 254.82 28.95 254.82 6.43 266.95 6.43" />
                        <path d="M284.68,6.43V23a6.5,6.5,0,0,1-6.66,6.3h-.51a6.53,6.53,0,0,1-6.66-6.34V6.43h4.09V23a2.59,2.59,0,0,0,2.67,2.48h.32a2.56,2.56,0,0,0,2.64-2.45V6.43Z" />
                        <polygon points="302.33 6.43 302.33 28.95 298.12 28.95 293.94 18.17 292.78 14.57 292.65 14.57 292.87 18.3 292.87 28.95 288.88 28.95 288.88 6.43 293.1 6.43 297.25 17.08 298.44 20.81 298.57 20.81 298.34 17.05 298.34 6.43 302.33 6.43" />
                        <path d="M320,23a6.44,6.44,0,0,1-6.59,6.3h-.52a6.56,6.56,0,0,1-6.66-6.34V12.41a6.53,6.53,0,0,1,6.66-6.3h.52A6.42,6.42,0,0,1,320,12.77h-4.09a2.66,2.66,0,0,0-2.63-2.86H313a2.5,2.5,0,0,0-2.64,2.34V23.13A2.52,2.52,0,0,0,313,25.51h.32a2.5,2.5,0,0,0,2.63-2.35V20.52h-2.79V16.79H320Z" />
                    </g>
                    <path
                        id="S"
                        d="M178.57,12.77a2.68,2.68,0,0,0-2.51-2.9,2.37,2.37,0,0,0-2.51,2.22c0,3.7,9.36,3.48,9.36,10.88a6.46,6.46,0,0,1-6.62,6.3h-.52a6.5,6.5,0,0,1-6.66-6.3v-1h4.09v1a2.63,2.63,0,0,0,2.64,2.54h.35a2.54,2.54,0,0,0,2.61-2.32c0-4.63-9.33-3.73-9.33-10.81a6.58,6.58,0,0,1,13.15.39Z"
                    />
                    <g id="ER">
                        <polygon points="140.27 6.43 140.27 10.2 132.23 10.2 132.23 15.31 139.56 15.31 139.56 19.07 132.23 19.07 132.23 25.18 140.53 25.18 140.53 28.95 128.18 28.95 128.18 6.43 140.27 6.43" />
                        <path d="M150.2,21.29h-1.67V29h-4.05V6.43h6.65a5.56,5.56,0,0,1,5.73,5.44v4a5.51,5.51,0,0,1-2.64,4.6l3.7,8.46h-4.44Zm2.51-9.58a1.58,1.58,0,0,0-1.64-1.51h-2.54v7.33h2.54A1.59,1.59,0,0,0,152.71,16Z" />
                    </g>
                    <g id="D">
                        <path d="M124.13,22.68a6.42,6.42,0,0,1-6.6,6.27H111V6.43h6.49a6.42,6.42,0,0,1,6.6,6.27ZM120,12.58a2.43,2.43,0,0,0-2.51-2.38h-2.35v15h2.35A2.4,2.4,0,0,0,120,22.84Z" />
                    </g>
                    <g id="ERREN">
                        <polygon points="18.02 6.43 18.02 28.95 30.37 28.95 30.37 25.18 22.07 25.18 22.07 19.07 29.4 19.07 29.4 15.31 22.07 15.31 22.07 10.2 30.11 10.2 30.11 6.43 18.02 6.43" />
                        <path d="M40.29,21.29H38.62V29h-4V6.43h6.66A5.56,5.56,0,0,1,47,11.87v4a5.52,5.52,0,0,1-2.63,4.6L48,29H43.58Zm2.51-9.58a1.58,1.58,0,0,0-1.64-1.51H38.62v7.33h2.54A1.59,1.59,0,0,0,42.8,16Z" />
                        <path d="M57.28,21.29H55.6V29H51.55V6.43h6.66a5.57,5.57,0,0,1,5.73,5.44v4a5.53,5.53,0,0,1-2.64,4.6L65,29H60.56Zm2.51-9.58a1.58,1.58,0,0,0-1.64-1.51H55.6v7.33h2.55A1.59,1.59,0,0,0,59.79,16Z" />
                        <polygon points="80.63 6.43 80.63 10.2 72.59 10.2 72.59 15.31 79.92 15.31 79.92 19.07 72.59 19.07 72.59 25.18 80.89 25.18 80.89 28.95 68.53 28.95 68.53 6.43 80.63 6.43" />
                        <polygon points="98.28 6.43 98.28 28.95 94.06 28.95 89.88 18.17 88.72 14.57 88.6 14.57 88.82 18.3 88.82 28.95 84.83 28.95 84.83 6.43 89.05 6.43 93.19 17.08 94.38 20.81 94.51 20.81 94.29 17.05 94.29 6.43 98.28 6.43" />
                    </g>
                    <polygon
                        id="H"
                        points="9.39 6.43 9.39 15.25 4.02 15.25 4.02 6.43 0 6.43 0 28.95 4.02 28.95 4.02 19.07 9.39 19.07 9.39 28.95 13.45 28.95 13.45 6.43 9.39 6.43"
                    />
                </svg>
            </div>
        )
    }
)
